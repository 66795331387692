<template>
    <div id="FileTemplateManage">
        <h2>模板管理</h2>
        <Form ref="templateQueryForm" :model="formInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td width="23%">
                        <FormItem prop="mstatus" label="分类" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.class" @on-change="onclasschange">
                                <Option value=null key=100>全部</Option> 
                                <Option value=1 key=1>验厂文件（大夹子）</Option> 
                                <Option value=2 key=2>采购类文件</Option> 
                                <Option value=3 key=3>检验类文件</Option> 
                                <Option value=4 key=4>记录类文件</Option>   
                                <Option value=5 key=5>售后类文件</Option>    
                                <Option value=6 key=6>生产设备类文件</Option>  
                                <Option value=7 key=7>检测设备类文件</Option>  
                            </Select>
                        </FormItem>
                    </td>
                    <td align="right" width="23%">
                        <FormItem  label="模板名称:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="formInline.templateName">
                            </i-input>
                        </FormItem>
                    </td>
                    <td rowspan="2" colspan="2" align="right">
                        <div>
                            <Button type="primary" @click="handleQuery()" >模板查询</Button>    
                            <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">模板文件列表</label>  
            <Button v-if="showinitbtn" type="primary"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="initTemplates()">初始化模板库</Button>
            <Button v-if="showinitbtn==false && userlevel == 100" type="primary"  style="margin-top:10px;float:left;margin-left:100px;font-size:10px;height:70%" @click="askinitTemplates()">重新初始化模板库</Button>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div> 
        <Table :columns="tblcolumns" :data="tbldata" stripe border style="margin-top: 10px;">
            <template slot-scope="{ row, index }" slot="action" >
                    <div  class="opbar">
                        <Button type="success" size="small" ghost @click="view(index)">查看</Button>
                        <Button type="info" size="small" @click="modify(index)">修改</Button>
                        <Button type="error" size="small" @click="init(index)" v-if="userlevel == 100">初始化</Button>           
                    </div>
            </template>
        </Table>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>

        <Modal v-model="showinitconfirm" title="初始化模板" @on-ok="doinit()">模板初始化，将会丢弃您对该模板做的一切修改，从公共模板库读取模板内容进行覆盖，确定要初始化吗？</Modal>
        <Modal v-model="showloading" 
         :closable="false"
         :footer-hide="true"
         :mask-closable="false"
         >
            <div style="display: inline-block;
                    width: 100%;
                    height: 100px;
                    position: relative;
                    border: 1px solid #eee;">
                <Spin fix>
                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                    <div>正在初始化模板，请稍后...</div>
                </Spin>
            </div>
        </Modal>
    </div>
</template>

<script>
export default {
    name:'FileTemplateManage',
    data () {
        return {
            userlevel:this._self.$root.$store.state.LogonInfo.level,
            companyid:this._self.$root.$store.state.LogonInfo.companyid,
            userCategory:this._self.$root.$store.state.LogonInfo.userCategory,
            show:[false,false,false,false,false],
            showtemplateview:false,
            showinitconfirm:false,
            showloading:false,
            showinitbtn:false,
            opindex:null,
            viewcontent:null,
            formInline:{
                class:null,
                templateName:null
            },
            tblpageinfo:{
                total:0,
                currentpage:1,
                pagesize:20
            },
            tblcolumns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '分类',
                    key: 'classname',
                },
                {
                    title: '模板名称',
                    key: 'templatename',
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',
                    fixed: 'right'
                }
                
            ],
            tbldata: [
                {
                    class:' 认证运行文件（大夹子）',
                    templateName:'质量手册',
                    templateNote:'质量管理文件'
                },
                {
                    class:' 认证运行文件（大夹子）',
                    templateName:'程序文件',
                    templateNote:'程序文件程序文件程序文件程序文件'
                },
                {
                    class:' 认证运行文件（大夹子）',
                    templateName:'管理制度',
                    templateNote:'制度管理规范文件'
                },
                {
                    class:' 采购类文件',
                    templateName:'采购合同',
                    templateNote:'质量管理文件'
                },
                {
                    class:' 采购类文件',
                    templateName:'供方评价表',
                    templateNote:'质量管理文件'
                }
            ]
        }
    },
    mounted(){
        console.log(this.userlevel)
        this.handleQuery()
    },
    methods:{
        clickopenprod(index){
            this.show[index] = !this.show[index]
        },
        view(index){
            //if(this.userCategory == "superadmin"){
                console.log(index+' -- >  '+this.tbldata[index])
               // this.$router.push({path:'/workdesk/SuperAdmin/TemplateEdit'})
                let routeUrl = this.$router.resolve({
                    path: "/workdesk/TemplateView",
                    query:{templateid:window.btoa(this.tbldata[index].id)}
                });
                window.open(routeUrl.href, '_blank');
            //}
        },
        modify(index){
            //if(this.userCategory == "superadmin"){
                console.log(index+' -- >  '+this.tbldata[index])
               // this.$router.push({path:'/workdesk/SuperAdmin/TemplateEdit'})
                let routeUrl = this.$router.resolve({
                    path: "/workdesk/TemplateEdit",
                    query:{templateid:window.btoa(this.tbldata[index].id)}
                });
                window.open(routeUrl.href, '_blank');
            //}
        },
        initTemplates(){
            //this.$Loading.start();
            this.showloading = true
            this.$axios({
                method:'post',
                url:'/api/checkdept/template/private/initfrompublic',
                data: {companyid: this.companyid},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    //this.$Loading.finish();
                    //this.$Modal.remove();
                    this.showloading = false
                    this.handleQuery()
                    //this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    //this.$Loading.error();
                    //this.$Modal.remove();
                    this.showloading = false
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        askinitTemplates(){
            this.$Modal.confirm({
                content:'确定要重新初始化模板吗？初始化后，您之前对模板文件做的修改都将无法保留',
                onOk:()=>{
                  this.initTemplates()
                }
              })
        },
        init(index){
            this.opindex = index
            this.showinitconfirm = true
        },
        doinit(){
            this.showloading = true
            this.$axios({
                method:'post',
                url:'/api/checkdept/template/private/initsingle',
                data: {templateid:this.tbldata[this.opindex].id},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.showloading = false
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.showloading = false
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        onpagechange(num){
            this.tblpageinfo.currentpage = num
            this.handleQuery()
        },
        onclasschange(id){
            if(id != 'null'){
                this.formInline.class = id
            }
            else{
                this.formInline.class = null
            }
        },
        handleQuery(){
            let postdata={
                classid: this.formInline.class,
                templatename:this.formInline.templateName,
                companyid: this.companyid,
                userlevel: this.userlevel,
                usercategory: this.userCategory,
                pageNum: this.tblpageinfo.currentpage,
                pageSize: this.tblpageinfo.pagesize
            }

            for(var p in postdata){
                if(postdata[p] == null){
                    delete postdata[p]
                }
            }

            let posturl
            if(this.userCategory=='superadmin'){
               posturl ='/api/checkdept/template/public/query' 
            }
            else{
                posturl ='/api/checkdept/template/private/query' 
            }
            this.$axios({
                    method:'post',
                    url:posturl,
                    data: postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        if(res.data.data.totalnumber == 0){
                            this.tbldata=[]
                            this.tblpageinfo.total =0
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =res.data.data.data
                        }
                        this.checkshowinitbtn()
                        console.log(this.userlevel)
                        console.log(this.tblpageinfo.total)
                        this.$Message.success({content:res.data.msg, duration:3})
                        if(this.userCategory !='superadmin')    {
                            eventBus.$emit('templateupdate',{totalnumber: this.tblpageinfo.total})
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
        },
        handleReset(){

        },
        checkshowinitbtn(){
            if((this.formInline.class == null || this.formInline.class == 'null') && 
            (this.formInline.templateName == null ||this.formInline.templateName == '') &&
             this.tblpageinfo.total ==0 && this.userlevel == 100 
            ){
                this.showinitbtn = true
            }
            else{
                this.showinitbtn = false
            }
        }
    },
    computed:{

    }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 10px;
    }

    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
</style>